import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import configs from "../../../../configs/apiConfigs";
import { fetchClinicProfile, fetchConsultationsSlots, fetchConsultationType } from "../../../../services/dentist";
import { showAddress } from "../../components/ShowAddress/ShowAddress";
import "./css/style.css";
import { Avatar } from "@material-ui/core";
import { AppointmentScheduling } from "../../components/AppointmentScheduling/AppointmentScheduling";
import moment from "moment";
import { RatingComp } from "../../../RatingComponent";
import { loadMap, setGoogleMap, setMarkerToPostion } from "../../../../utils/googleFunction";
import { fetchReviews } from "../../../../services/results";
import { Helmet } from "react-helmet";
let googleMap;
export const ClinicProfileNew = () => {
    const params: any = useParams();
    const [clinicProfile, setClinicProfile]: any = useState(null);
    const [consultationTypes, setConsultationTypes]: any = useState(null);
    const [selectedConsultation, setSelectedConsultation] = useState("");
    const [slots, setSlots] = useState([]);
    const [firstAvailable, setFirstAvailable] = useState(null);
    const [reviews, setReviews]: any = useState(null);
    useEffect(() => {
        callFetchClinicProfile();
        callFetchClinicReviews();
        loadMap().then((success) => { }, (err) => { console.log(err); });
        window.scrollTo(0, 0)
    }, []);
    const callFetchClinicProfile = async () => {
        try {
            const response: any = await fetchClinicProfile(params.uniqueId);
            if (response.response.data.status.error === false) {
                const data = response.response.data;
                setClinicProfile(data);
                callFetchConsultationTypes(data);
                const latitude = parseFloat(data.address.latitude);
                const longitude = parseFloat(data.address.longitude);
                setGoogleMap({ latitude: latitude, longitude: longitude }, 'map')?.then((success: any) => {
                    googleMap = success;
                    setMarkerToPostion(googleMap, { latitude: latitude, longitude: longitude });
                }).catch((err) => {
                    console.log(err);
                });
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    const callFetchConsultationTypes = async (clinicProfile: any) => {
        try {
            const response: any = await fetchConsultationType(params.uniqueId, "provider=default");
            if (response.response.data.status.error === false) {
                setConsultationTypes(response.response.data);
                setSelectedConsultation(response?.response?.data?.types?.[0]?.id);
                callSlots({
                    "doctorId": response?.response?.data?.defaultProvider?.id,
                    "clinicId": clinicProfile?.id,
                    "start_date": moment().format("YYYY-MM-DD"),
                    "end_date": moment().add(3, 'day').format("YYYY-MM-DD"),
                    "consultationTypeId": response?.response?.data?.types?.[0]?.id
                });
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    const callSlots = async (obj: any) => {
        try {
            console.log(obj);
            let _obj = {
                doctorId: consultationTypes?.defaultProvider?.id,
                clinicId: clinicProfile?.id,
                consultationTypeId: selectedConsultation,
                ...obj,
            }
            const response: any = await fetchConsultationsSlots({
                appointmentData: [_obj]
            });
            setSlots(response?.response?.data?.appointments ?? []);
            setFirstAvailable(response?.response?.data?.firstAvailable);
            console.log("response", response);
        } catch (err) {
            console.log("err", err);
        }
    }
    const callFetchClinicReviews = async () =>{
        try {
            const response: any = await fetchReviews(params.uniqueId);
            console.log(response);
            setReviews(response?.response?.data);
        } catch (err) {
            console.log("err", err);
        }
    }
    let services: any[] = [];
    if (clinicProfile?.doctors?.length > 0) {
        clinicProfile?.doctors?.forEach((value: any) => {
            if (value.services && value.services.length > 0) {
                value.services.forEach((serviceParent: any) => {
                    const findService = services.findIndex((service: any) => { return service.id.toString() === serviceParent.id.toString() });
                    if (findService === -1) {
                        services.push(serviceParent);
                    }
                })
            }
        });
    }
    return (
        <>
            <Helmet>
                <title>{clinicProfile?.seodata?.metaTitle}</title>
                <meta name="description" content={clinicProfile?.seodata?.metaDesc} />
                <meta name="keywords" content={clinicProfile?.seodata?.metaKeywords} />
            </Helmet>
            <main>
                {/* <div className="main-banner">
                    <div className="container h-100">
                        <div className="row h-100 align-items-end">
                            <div className="col-12">
                                <h1>Welcome to {clinicProfile?.name}</h1>
                            </div>
                        </div>
                    </div>
                    <img src={configs.IMAGE_URL + 'clinic/' + clinicProfile?.banner?.name + '-original.' + clinicProfile?.banner?.type} alt="banner-img" />
                </div> */}
                <div className="main-grid-sec">
                    <div className="container">
                        <div className="row d-sm-block d-md-none">
                            <div className="col-12">
                            {
                                    clinicProfile?.onlineSchedulingLogoBanner &&
                                    <div className="practice-profile d-flex align-items-center">
                                        <div className="img-box d-flex align-items-center">
                                            {
                                                clinicProfile?.logo?.name && clinicProfile?.logo.type ?
                                                    <img src={configs.IMAGE_URL + 'clinic/' + clinicProfile.logo.name + '-200x200.' + clinicProfile.logo.type} alt="" className="img-fluid" /> :
                                                    <img src={configs.DEFAULT_IMAGE_URL_CLINIC} alt="" className="img-fluid" />
                                            }
                                        </div>
                                        <div className="content-box ms-4 ml-4">
                                            <div className="trusted-txt">
                                                <img src={require("./images/trusted-icon.svg")} className="mr-2" />Trusted by XcarePro
                                            </div>
                                            <div className="p-name">{clinicProfile?.name}</div>
                                            <div id="clinicAddress" className="p-address">
                                                {
                                                    showAddress(clinicProfile?.address ?? "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7 order-md-1 order-2">
                                {
                                    clinicProfile?.onlineSchedulingLogoBanner &&
                                    <div className="practice-profile d-none d-sm-none d-md-flex align-items-center">
                                        <div className="img-box d-flex align-items-center">
                                            {
                                                clinicProfile?.logo?.name && clinicProfile?.logo.type ?
                                                    <img src={configs.IMAGE_URL + 'clinic/' + clinicProfile.logo.name + '-200x200.' + clinicProfile.logo.type} alt="" className="img-fluid" /> :
                                                    <img src={configs.DEFAULT_IMAGE_URL_CLINIC} alt="" className="img-fluid" />
                                            }
                                        </div>
                                        <div className="content-box ms-4 ml-4">
                                            <div className="trusted-txt">
                                                <img src={require("./images/trusted-icon.svg")} className="mr-2" />Trusted by XcarePro
                                            </div>
                                            <div className="p-name">{clinicProfile?.name}</div>
                                            <div id="clinicAddress" className="p-address">
                                                {
                                                    showAddress(clinicProfile?.address ?? "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="tab-view-practice">
                                    { clinicProfile?.onlineSchedulingPageHeader &&
                                        <div className="tab-strip border-top border-bottom py-3 d-lg-flex d-none">
                                            {
                                                clinicProfile?.onlineSchedulingAbout &&
                                                <a href={`#tab-about`} className="mr-3">About</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingPracticePhoto &&
                                                <a href={`#tab-photos`} className="mr-3">Practice Photos</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingProviders &&
                                                <a href={`#tab-providers`} className="mr-3">Providers</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingReview &&
                                                <a href={`#tab-reviews`} className="mr-3">Reviews</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingFeatures &&
                                                <a href={`#tab-features`} className="mr-3">Features</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingServices &&
                                                <a href={`#tab-services`} className="mr-3">Services</a>
                                            }
                                        </div>   
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingAbout &&
                                        <div id="tab-about">
                                        <h3>Welcome to {clinicProfile?.name}</h3>
                                        <p dangerouslySetInnerHTML={{__html: clinicProfile?.description ?? ""}}></p>
                                    </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingPracticePhoto &&
                                        <div id="tab-photos">
                                        <h3>Practice Photos</h3>
                                        <div className="d-flex practice-pics justify-content-between">
                                            {
                                                clinicProfile?.photos?.slice(0,4)?.map((photo: any, index: number) => {
                                                    if (photo.image && photo.image.name && photo.image.type) {
                                                        return (
                                                            <div className="img-box rounded mr-3">
                                                                <img src={configs.IMAGE_URL + "/clinic/" + photo.image.name + '-500x500.' + photo.image.type} alt="" />
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingFeatures &&
                                        <div>
                                        <h3>Preferences & Highlights</h3>
                                        <div className="d-flex highlights flex-wrap">
                                            <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                <img src={require("./images/p1.svg")} alt="" className="mr-3" />
                                                <small>Accepting new patients</small>
                                            </div>
                                            <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                <img src={require("./images/p2.svg")} alt="" className="mr-3" />
                                                <small>Available for weekend</small>
                                            </div>
                                            <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                <img src={require("./images/p3.svg")} alt="" className="mr-3" />
                                                <small>Insurance Accepted All or selected</small>
                                            </div>
                                            <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                <img src={require("./images/p1.svg")} alt="" className="mr-3" />
                                                <small>Social assistance programs accepted</small>
                                            </div>
                                            <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                <img src={require("./images/p1.svg")} alt="" className="mr-3" />
                                                <small>Financing plans available</small>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingProviders &&
                                        <div id="tab-providers">
                                        <h3>Providers</h3>
                                        {
                                            clinicProfile?.doctors.map((doctor: any, index: number) => {
                                                let coreSpeciality = '';
                                                if (doctor.specialities) {
                                                    const coreSpecialityFound = doctor.specialities.findIndex((speciality: any) => {
                                                        return speciality.coreSpeciality === "1";
                                                    });
                                                    if (coreSpecialityFound !== -1) {
                                                        coreSpeciality = doctor.specialities[coreSpecialityFound].name;
                                                    } else {
                                                        const specialityFound = doctor.specialities.findIndex((speciality: any) => {
                                                            return speciality.coreSpeciality !== "1";
                                                        });
                                                        coreSpeciality = doctor.specialities[specialityFound].name;
                                                    }
                                                }
                                                let firstField = '';
                                                let secondField = '';
                                                if (clinicProfile?.address) {
                                                    if (clinicProfile?.address.sublocality && clinicProfile?.address.sublocality !== "") {
                                                        firstField = clinicProfile?.address.sublocality;
                                                    } else if (clinicProfile?.address.neigborhood && clinicProfile?.address.neigborhood !== "") {
                                                        firstField = clinicProfile?.address.neigborhood;
                                                    } else if (clinicProfile?.address.locality && clinicProfile?.address.locality !== "") {
                                                        firstField = clinicProfile?.address.locality;
                                                    }

                                                    if (clinicProfile?.address.address_level_1_short && clinicProfile?.address.address_level_1_short !== "") {
                                                        secondField = clinicProfile?.address.address_level_1_short;
                                                    }
                                                }
                                                let doctorName = doctor.fullname.replace("Dr.", "");
                                                doctorName = doctorName.trim();
                                                doctorName = doctorName.split(" ");
                                                const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                                const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                                return (
                                                    <div
                                                        className="practice-list-desc d-flex rounded p-3 align-items-center justify-content-between mb-2">
                                                        <div className="d-flex align-items-center">
                                                            <div className="img-box mr-2">
                                                                {
                                                                    (doctor.photo && doctor.photo.name && doctor.photo.type) ?
                                                                        <img src={configs.IMAGE_URL + '/doctor/' + doctor.photo.name + '-200x200.' + doctor.photo.type} /> : <Avatar>{letterOne}{letterTwo}</Avatar>
                                                                }
                                                            </div>
                                                            <div>
                                                                <h4>{doctor?.fullname ?? ""}</h4>
                                                                <div className="small d-flex ">
                                                                    <span className="pr-2 border-right mr-2">{coreSpeciality}</span>
                                                                    <div className="trusted-txt text-primary">
                                                                        <img src={require("./images/trusted-icon.svg")} className="mr-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <NavLink to={"/" + doctor.fullname.trim().toLowerCase().replace(/[,._+]/g, '').replace(/\s+/g, "-") + "/" + doctor.providerId + "/d"} className="btn btn-primary btn-sm mr-3">
                                                                Book now
                                                            </NavLink>
                                                            <NavLink to={"/" + doctor.fullname.trim().toLowerCase().replace(/[,._+]/g, '').replace(/\s+/g, "-") + "/" + doctor.providerId + "/d"} className="btn btn-dark btn-sm">
                                                                View profile
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    }
                                    {
                                        reviews?.reviews?.length > 0 && clinicProfile?.onlineSchedulingReview &&
                                        <div id="tab-reviews">
                                            <div className="d-flex pb-3 border-bottom justify-content-between align-items-center">
                                                <div>
                                                    <h3 className="mb-1">Patient reviews</h3>
                                                    <p className="m-0">Read About Other Patients' Experiences</p>
                                                </div>
                                                <div className="review-detail-box px-md-3 px-2 py-md-2 py-1">
                                                    <div className="avg-rating">
                                                        <strong>{reviews?.rating?.avg}</strong>/5 <span className="ml-1 static-txt">Avg. Rating</span>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center">
                                                        <RatingComp rating={reviews?.rating?.avg ?? 0}></RatingComp>
                                                        <span className="no-of-review ml-2">{reviews?.rating?.total} Reviews</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                reviews?.reviews?.map((item: any, index: number) => {
                                                    return (
                                                        <div className="review-box-nw d-flex my-3" key={index + "review"}>
                                                            <div className="img-box mr-3">
                                                                <img src={configs.DEFAULT_IMAGE_URL_CLINIC} alt="" />
                                                            </div>
                                                            <div className="pb-3 border-bottom">
                                                                <RatingComp rating={Number(item.rating)}></RatingComp>
                                                                <div>{item.reviewText}</div>
                                                                <div className="date-review small">{moment(item.date).format("MMM. DD, YYYY")}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingFeatures &&
                                        <div id="tab-features">
                                            <h3>Features</h3>
                                            <ul className="d-flex flex-wrap">
                                                {
                                                    clinicProfile?.features?.map((feature: any, index: number) => (
                                                        <li key={index + "feature"}>{feature?.name}</li>
                                                    ))
                                                }
                                            </ul>
                                            {/* <a href="" className="text-primary">View more +</a> */}
                                        </div>
                                    }
                                    {clinicProfile?.onlineSchedulingServices && <div id="tab-services">
                                        <h3>Services</h3>
                                        <ul className="d-flex flex-wrap">
                                            {
                                                services?.map((service, index) => (
                                                    <li key={index + "service"}>{service.name}</li>
                                                ))
                                            }
                                        </ul>
                                        {/* <a href="" className="text-primary">View more +</a> */}
                                    </div>}
                                </div>
                            </div>
                            <div className="col-md-5 order-md-2 order-1">
                                <div className="practice-sidebar">
                                    <AppointmentScheduling
                                        handleConsultationTypeChange={(value: any, obj: any) => {
                                            setSelectedConsultation(value);
                                            callSlots(obj);
                                        }}
                                        selectedConsultation={selectedConsultation}
                                        consultationTypes={consultationTypes}
                                        slots={slots}
                                        clinicName={clinicProfile?.name}
                                        clinicId={clinicProfile?.id}
                                        doctorId={consultationTypes?.defaultProvider?.id}
                                        appointmentTypeId={consultationTypes?.defaultProvider?.appointmentTypes?.[0]?.id ?? ""}
                                        clinicUniueId={params.uniqueId}
                                        firstAvailable={firstAvailable}
                                        flexibleSchedulingOn={clinicProfile?.onlineSchedulingFlexibleSchedule}
                                    ></AppointmentScheduling>
                                    {
                                        clinicProfile?.onlineSchedulingLocation &&
                                        <div className="loc-sec border p-3 mt-4">
                                        <h3 className="mb-3">Location</h3>
                                        <div className="mb-3">
                                            <div className="map-sec-client">
                                                <div id="map" className="map-sec kflrui" style={{ height: "250px" }}></div>
                                            </div>
                                        </div>
                                        <h3>{clinicProfile?.name}</h3>
                                        {
                                            showAddress(clinicProfile?.address ?? "")
                                        }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


            <footer className="border-top py-3 text-right new-footer ">
                <div className="container">
                    <div className="row">
                        <div className="col-12">© 2022 Powered by XcarePro</div>
                    </div>
                </div>
            </footer>
        </>
    )
}