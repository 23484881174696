import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import configs from "../../../../configs/apiConfigs";
import { fetchConsultationsSlots, fetchConsultationType, fetchDoctorProfile } from "../../../../services/dentist";
import { showAddress } from "../../components/ShowAddress/ShowAddress";
import { loadMap, setGoogleMap, setMarkerToPostion } from "../../../../utils/googleFunction";
import { AppointmentSchedulingXcare } from "../../components/AppointmentSchedulingXcare/AppointmentSchedulingXcare";
import moment from "moment";
import { fetchReviews } from "../../../../services/results";
import { RatingComp } from "../../../RatingComponent";
import HeaderWhite from "../HeaderWhite/HeaderWhite";
let googleMap;
export const ProviderProfileNewXcare = () => {
    const params: any = useParams();
    const [doctorProfile, setDoctorProfile]: any = useState(null);
    const [consultationTypes, setConsultationTypes]: any = useState(null);
    const [selectedConsultation, setSelectedConsultation] = useState("");
    const [slots, setSlots] = useState([]);
    const [firstAvailable, setFirstAvailable] = useState(null);
    const [reviews, setReviews]: any = useState(null);
    useEffect(() => {
        callFetchDoctorProfile();
        loadMap().then((success) => { }, (err) => { console.log(err); });
        window.scrollTo(0, 0)
    }, []);
    const callFetchDoctorProfile = async () => {
        try {
            const response: any = await fetchDoctorProfile(params.uniqueId);
            if (response.response.data.status.error === false) {
                const data: any = response.response.data;
                setDoctorProfile(data);
                callFetchConsultationTypes(data.uniqueId, data);
                callFetchClinicReviews(data.uniqueId);
                const latitude = parseFloat(data.address.latitude);
                const longitude = parseFloat(data.address.longitude);
                setGoogleMap({ latitude: latitude, longitude: longitude }, 'map')?.then((success: any) => {
                    googleMap = success;
                    setMarkerToPostion(googleMap, { latitude: latitude, longitude: longitude });
                }).catch((err) => {
                    console.log(err);
                });
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    const callFetchConsultationTypes = async (uniqueId: string, doctorProfile: any) => {
        try {
            const response: any = await fetchConsultationType(uniqueId, "provider=" + params.uniqueId);
            if (response.response.data.status.error === false) {
                setConsultationTypes(response.response.data);
                setSelectedConsultation(response?.response?.data?.types?.[0]?.id);
                callSlots({
                    "doctorId": doctorProfile?.provider?.id,
                    "clinicId": doctorProfile?.id,
                    "start_date": moment().format("YYYY-MM-DD"),
                    "end_date": moment().add(3, 'day').format("YYYY-MM-DD"),
                    "consultationTypeId": response?.response?.data?.types?.[0]?.id
                });
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    const callSlots = async (obj: any) => {
        try {
            console.log(obj);
            let _obj = {
                doctorId: doctorProfile?.provider?.id,
                clinicId: doctorProfile?.id,
                consultationTypeId: selectedConsultation,
                ...obj,
            }
            const response: any = await fetchConsultationsSlots({
                appointmentData: [_obj]
            });
            setSlots(response?.response?.data?.appointments ?? []);
            setFirstAvailable(response?.response?.data?.firstAvailable);
            console.log("response", response);
        } catch (err) {
            console.log("err", err);
        }
    }
    const callFetchClinicReviews = async (uniqueId: string) => {
        try {
            const response: any = await fetchReviews(uniqueId);
            console.log(response);
            setReviews(response?.response?.data);
        } catch (err) {
            console.log("err", err);
        }
    }
    let speciality = '';
    if (doctorProfile?.specialities) {
        const elementFound = doctorProfile?.specialities?.findIndex((spec: any, index: number) => {
            return spec.coreSpeciality === "1"
        });
        if (elementFound === -1 && doctorProfile?.specialities.length > 0) {
            speciality = doctorProfile.specialities[0].name;
        } else {
            speciality = doctorProfile?.specialities[elementFound].name;
        }
    }
    let doctorName = (doctorProfile && doctorProfile?.name) ? doctorProfile?.name?.replace("Dr.", "") : '';
    doctorName = doctorName.trim();
    doctorName = doctorName.split(" ");
    const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
    const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
    let parsedCertifications = [];
    try {
        parsedCertifications = doctorProfile?.certificates ? JSON.parse(doctorProfile?.certificates) : [];
    } catch (err) {
        parsedCertifications = [];
    }
    console.log("parsedCertifications", parsedCertifications);
    return (
        <>
            <HeaderWhite></HeaderWhite>
            <section>
                {/* <div className="main-banner">
                    <div className="container h-100">
                        <div className="row h-100 align-items-end">
                            <div className="col-12">
                                <h1>Welcome to {doctorProfile?.name}</h1>
                            </div>
                        </div>
                    </div>
                    {
                        (doctorProfile?.banner) ?
                            <img src={configs.IMAGE_URL + 'clinic/' + doctorProfile?.banner?.name + '-original.' + doctorProfile?.banner.type} alt="banner-img" /> :
                            <img src={process.env.PUBLIC_URL + "/images/p-seo-banner-img.png"} alt="banner-img" />
                    }
                </div> */}
                <div className="main-grid-sec provider-profile-grid" style={{marginTop: 80}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 order-md-1 order-2">

                                <div className="practice-profile d-flex align-items-center">
                                    <div className="img-box d-flex align-items-center p-0">
                                        {
                                            doctorProfile?.provider?.photo ?
                                                <img src={configs.IMAGE_URL + 'doctor/' + doctorProfile?.provider?.photo?.name + '-300x300.' + doctorProfile?.provider?.photo?.type} alt="" className="img-fluid" /> :
                                                <Avatar>{letterOne}{letterTwo}</Avatar>
                                        }
                                    </div>
                                    <div className="content-box ms-4 ml-4">
                                        <div className="trusted-txt">
                                            <img src={require("./images/trusted-icon.svg")} className="mr-2" />Trusted by XcarePro
                                        </div>
                                        <div className="p-name">{doctorProfile?.provider?.fullname}</div>
                                        <div id="clinicAddress" className="p-address">
                                            <div>
                                                <span>{speciality} -</span>
                                                <NavLink to={'/' + doctorProfile?.name.split(' ').join('-') + '/' + doctorProfile?.uniqueId + '/c'}>{doctorProfile?.name}</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-view-practice">

                                    <div className="tab-strip border-top border-bottom py-3 d-lg-flex d-none">
                                        <a href="#tab-about" data-href="tab-about" className="mr-3">About</a>
                                        <a href="#tab-photos" data-href="tab-photos" className="mr-3">Photos</a>
                                        <a href="#tab-reviews" data-href="tab-reviews" className="mr-3">Reviews</a>
                                        <a href="#tab-location" data-href="tab-location" className="mr-3">Location</a>
                                    </div>


                                    <div id="tab-about">
                                        <h3>About {doctorProfile?.provider?.fullname}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: doctorProfile?.provider?.description ?? "" }}></p>
                                    </div>

                                    <div id="tab-photos">
                                        <h3>Practice Photos</h3>
                                        <div className="d-flex practice-pics justify-content-between">
                                            {
                                                doctorProfile?.photos?.slice(0, 4)?.map((photo: any, index: number) => {
                                                    if (photo.image && photo.image.name && photo.image.type) {
                                                        return (
                                                            <div className="img-box rounded mr-3">
                                                                <img src={configs.IMAGE_URL + "/clinic/" + photo.image.name + '-500x500.' + photo.image.type} alt="" />
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    </div>
                                    {/* <div>
                                        <h3>Education & Qualifications</h3>
                                        <div className="d-flex highlights flex-wrap">
                                            {
                                                parsedCertifications?.map((education: any, index: number) => (
                                                    <div className="highlight-box d-flex p-3 rounded border align-items-center" key={index}>
                                                        <div className="img-box mr-3 text-center">
                                                            <img src={require("./images/edu-icon.svg")} alt="" className="mr-3" />
                                                        </div>
                                                        <div>
                                                            <small className="d-block">Education</small>
                                                            <span className="small">{education.degree}</span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div> */}
                                    {
                                        reviews?.reviews?.length > 0 &&
                                        <div id="tab-reviews">
                                            <div className="d-flex pb-3 border-bottom justify-content-between align-items-center">
                                                <div>
                                                    <h3 className="mb-1">Patient reviews</h3>
                                                    <p className="m-0">Read About Other Patients' Experiences</p>
                                                </div>
                                                <div className="review-detail-box px-md-3 px-2 py-md-2 py-1">
                                                    <div className="avg-rating">
                                                        <strong>{reviews?.rating?.avg}</strong>/5 <span className="ml-1 static-txt">Avg. Rating</span>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center">
                                                        <RatingComp rating={reviews?.rating?.avg ?? 0}></RatingComp>
                                                        <span className="no-of-review ml-2">{reviews?.rating?.total} Reviews</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                reviews?.reviews?.map((item: any, index: number) => {
                                                    return (
                                                        <div className="review-box-nw d-flex my-3" key={index + "review"}>
                                                            <div className="img-box mr-3">
                                                                <img src={configs.DEFAULT_IMAGE_URL_CLINIC} alt="" />
                                                            </div>
                                                            <div className="pb-3 border-bottom">
                                                                <RatingComp rating={Number(item.rating)}></RatingComp>
                                                                <div>{item.reviewText}</div>
                                                                <div className="date-review small">{moment(item.date).format("MMM. DD, YYYY")}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-5 order-md-2 order-1">
                                <div className="practice-sidebar">
                                    <AppointmentSchedulingXcare
                                        handleConsultationTypeChange={(value: any, obj: any) => {
                                            setSelectedConsultation(value);
                                            callSlots(obj);
                                        }}
                                        selectedConsultation={selectedConsultation}
                                        consultationTypes={consultationTypes}
                                        slots={slots}
                                        providerName={doctorProfile?.provider?.fullname}
                                        clinicId={doctorProfile?.id}
                                        doctorId={doctorProfile?.provider?.id}
                                        appointmentTypeId={doctorProfile?.provider?.potentialAppointmentTypes?.[0]?.id ?? ""}
                                        clinicUniueId={doctorProfile?.uniqueId}
                                        firstAvailable={firstAvailable}
                                        flexibleSchedulingOn={doctorProfile?.onlineSchedulingFlexibleSchedule}
                                        source={params.location}
                                    ></AppointmentSchedulingXcare>
                                    <div className="loc-sec border p-3 mt-4" id="tab-location">
                                        <h3 className="mb-3">Location</h3>
                                        <div className="mb-3">
                                            <div className="map-sec-client">
                                                <div id="map" className="map-sec kflrui" style={{ height: "250px" }}></div>
                                            </div>
                                        </div>
                                        <h3>{doctorProfile?.name}</h3>
                                        <div>
                                            {
                                                showAddress(doctorProfile?.address ?? {})
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <footer className="border-top py-3 text-right new-footer ">
                <div className="container">
                    <div className="row">
                        <div className="col-12">© 2022 Powered by XcarePro</div>
                    </div>
                </div>
            </footer>
        </>
    )
}